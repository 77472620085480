<template>
  <v-container>
    <BaseTitlePage :title="checkPageTitle" />

    <v-tabs
      class="b-tab-vertical"
      show-arrows
      :vertical="checkMobile"
      :fixed-tabs="!checkMobile"
      v-model="tab"
    >
      <v-tab
        class="b-tab-vertical--tab"
        :disabled="header.disabled"
        :href="`#${header.href}`"
        :key="index"
        v-for="(header, index) in headers"
      >
        <v-icon small v-if="checkMobile">
          {{ header.icon }}
        </v-icon>
        {{ header.title }}
      </v-tab>

      <v-tabs-items v-model="tab" class="b-tab-vertical--items">
        <v-tab-item
          v-for="(content, index) in headers"
          :key="index"
          :value="content.href"
        >
          <component
            :is="content.component"
            :editMode="editMode"
            v-if="content.href === tab"
            @back="back()"
            @next-tab="nextTab($event)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';

export default {
  mixins: [rulesMixin],

  components: {
    PageRegistrationData: () =>
      import('@/components/pages/insuranceCompany/page-registration-data'),
    PageContacts: () =>
      import('@/components/pages/insuranceCompany/page-contacts'),
    PagePlans: () => import('@/components/pages/insuranceCompany/page-plans'),
    PageDocuments: () =>
      import('@/components/pages/insuranceCompany/page-documents')
  },

  data: () => ({
    tab: null
  }),

  computed: {
    editMode() {
      return this.$route.params && this.$route.params.id ? true : false;
    },

    checkPageTitle() {
      const { id } = this.$route.params;

      return id
        ? 'Visualizar operadora e/ou seguradora'
        : 'Cadastro de operadora e/ou seguradora';
    },

    checkMobile() {
      return this.$vuetify.breakpoint.smAndDown ? false : true;
    },

    headers() {
      const { cnpj } = this.$route.params;

      return [
        {
          href: 'tab-1',
          title: 'Dados Cadastrais',
          icon: 'fa-solid fa-briefcase',
          component: 'PageRegistrationData',
          disabled: false
        },
        {
          href: 'tab-2',
          title: 'Contatos',
          icon: 'fa-solid fa-user-plus',
          component: 'PageContacts',
          disabled: cnpj ? false : true
        },
        {
          href: 'tab-3',
          title: 'Planos',
          icon: 'fa-solid fa-file-lines',
          component: 'PagePlans',
          disabled: cnpj ? false : true
        },
        {
          href: 'tab-4',
          title: 'Documentos',
          icon: 'fa-regular fa-folder',
          component: 'PageDocuments',
          disabled: cnpj ? false : true
        }
      ];
    }
  },

  methods: {
    nextTab(param) {
      this.tab = `tab-${param}`;
    },

    back() {
      this.$router.push({ name: 'operadoras' });
    }
  }
};
</script>
